<template>
  <div>
    <div>
      <div class="buy_check_container">
        <div class="buy_check_title">
          <div class="buy_check_title_item">儲值項目</div>
          <div class="buy_check_title_amount">數量</div>
          <div class="buy_check_title_price">價格</div>
          <div class="buy_check_title_money">金額小計</div>
        </div>

        <div class="buy_check_main">
          <div class="buy_check_row">
            <div class="buy_check_item">{{rechargeOrder.shelfName}}</div>
            <div class="buy_check_amount">{{rechargeOrder.quantity}}</div>
            <div
              class="buy_check_price"
            >NT{{rechargeOrder.originalPrice-rechargeOrder.discountCash}}</div>
            <div
              class="buy_check_money"
            >NT{{rechargeOrder.totalOriginalPrice-rechargeOrder.totalDiscountCash}}</div>
          </div>

          <div class="buy_check_total_container">
            <div class="buy_item_line"></div>
            <div class="buy_check_total_infor">
              <div class="buy_check_discount">
                <span>優惠券代碼：{{rechargeOrder.couponNumber}}</span>
                <span>優惠券金額：{{rechargeOrder.preferentialCash}}元</span>
              </div>
              <div class="buy_check_point_infor">
                <span>點數合計：{{rechargeOrder.totalRechargePoint}}點</span>
                <span>點數金額：{{rechargeOrder.totalOriginalPrice-rechargeOrder.totalDiscountCash}}元</span>
              </div>
              <div class="buy_check_point_total">
                <span>應付金額：{{rechargeOrder.payForCash}}元</span>
              </div>
            </div>
          </div>
        </div>
        <div class="buy_check_tip">
          後續將前往第三方平台-綠界ezPay完成付款動作
          <br />如要取消此次儲值請直接關閉視窗即可
        </div>
        <div v-if="payIt" class="buy_check_btn buy_grey_btn">前往付款</div>
        <div v-else class="buy_check_btn" @click="createRecharge">前往付款</div>
      </div>

      <!-- 關閉視窗即取消此次儲值，使用者點下關閉視窗後，需alert提示(關閉視窗即取消此次儲值)一次，使用者按下離開才會關閉視窗 -->
      <!-- <img src="../../assets/image/alert_example.jpg" alt="範例參考，了解後可刪" /> -->
    </div>
    <div style="visibility: hidden; position: fixed;">
      <form :action="payforList.tradeUrl" target="_blank" id="mainForm" method="post">
        MerchantId:
        <input
          type="text"
          id="MerchantID"
          name="MerchantID"
          :value="payforList.MerchantID"
        />
        <br />TradeInfo:
        <input type="text" id="TradeInfo" name="TradeInfo" :value="payforList.TradeInfo" />
        <br />TradeSha:
        <input type="text" id="TradeSha" name="TradeSha" :value="payforList.TradeSha" />
        <br />Version:
        <input type="text" id="Version" name="Version" :value="payforList.Version" />
        <br />
        <input type="button" @click="submitEspay" />
      </form>
    </div>
  </div>
</template>
<script>
import {
  getInitRecharge,
  getCreateRecharge,
  getPayforRecharge
} from "../../api/api";
import { Message } from "element-ui";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      rechargeOrder: {},
      rechargeParam: {},
      payforList: {},
      dialogVisible: false,
      payIt: false
    };
  },
  created() {
    this.rechargeParam = this.$route.query;
    this.getRechargeList();
  },
  methods: {
    ...mapMutations(["setRechargeOrder"]),
    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "关闭提示";
      }
      return "关闭提示";
    },
    async getRechargeList() {
      const res = await getInitRecharge(this.rechargeParam);
      if (res.code == 200) {
        this.rechargeOrder = res.data;
      }
    },
    async createRecharge() {
      const res = await getCreateRecharge(this.rechargeParam);
      if (res && res.code == 200) {
        this.payIt = true;
        this.payforList = res.data;
        window.localStorage.setItem(
          "queryReachargeOrder",
          this.payforList.rechargeOrderId
        );
        // console.log(this.payforList);
        this.$nextTick(() => {
          this.submitEspay();
        });
      }
    },
    //获取espay交易页面
    submitEspay() {
      let dom = document.getElementById("mainForm");
      // console.log(dom);
      dom.submit();
    },
    handleClose() {
      this.dialogVisible = false;
    }
  },
  beforeDestroy() {}
};
</script>
<style scoped>
@import "../../assets/css/member_center.css";
.buy_grey_btn {
  background: #e0e0e0;
}
</style>